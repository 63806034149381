.attachmentMenu {
  position: absolute;
  bottom: 50px;
  left: 5px;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 0;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d5e7f5;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  & > div {
    width: 100%;
  }
}
