.container {
  position: relative;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100vh;
}

.image {
  width: 100%;
  position: relative;
}

.main {
  width: 100%;
  object-fit: cover;
  filter: brightness(20%);
  max-height: 100%;
  min-height: 100%;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.formBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 415px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  padding: 24px;
}

.submitButton {
  width: 100%;
}
