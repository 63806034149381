.artworkCard {
  width: 100%;
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
}

.titleRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #1677ff;
}

.cardHeader {
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-bottom: 1px solid rgba(5, 5, 5, 0.2) !important;
}

.cardTitle {
  white-space: normal !important;
  font-size: larger;
}

.pannelHead {
  display: flex;
  align-items: center;
  gap: 8px;
}

.row {
  margin-bottom: 12px;
}
