.root {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  &[data-padding='true'] {
    padding: 10px 10px 10px 0;
  }
}

.select {
  width: 100%;
}

.label {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 4px;
}
