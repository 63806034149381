.form {
  width: 100%;

  & label {
    font-weight: bold;
  }
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  & div {
    width: 100%;
  }
}

.rowGridBox {
  margin-top: 16px;
  & div {
    margin: 0;
  }
}

.rowGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  align-items: end;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #0000000f;
  margin: 16px 0 0;
}

.btn {
  margin-top: 24px;

  & button {
    width: 100%;
  }
}

.infoItem {
  display: flex;
  margin-bottom: 8px;
}

.value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.space {
  display: flex;
  align-items: center;
  width: 100%;
}

.formBox {
  width: 100%;
  display: flex;
  align-items: center;
}

.getRateBtn {
  align-self: flex-end;
  margin-bottom: 20px;
}
