.layout {
  display: flex;
  width: 100%;
  height: 100%;
}

.extraMenu {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 30px 0;
  background-color: #001529;
}

.extraMenuItem,
.extraMenuItemActive,
.extraMenuItemAddNew {
  width: 50px;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #324353;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    filter: brightness(85%);
  }
}

.extraMenuItemAddNew {
  border: 1px dashed #ccc;
}

.extraMenuItemActive {
  border: 1px solid #1677ff;
}

.content {
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  background-color: #fff;
}

.header {
  background-color: #fff;
  height: 96px;
  line-height: initial;
}
