.root {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  &[data-padding='true'] {
    padding: 10px 10px 10px 0;
  }
}
