.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
}

.editorWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.container {
  position: relative;
  min-width: 100%;
}

.counterWrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.counter {
  position: absolute;
  top: 13px;
  right: 10px;
}

.quillEditor {
  border-top-right-radius: 0;
}

.editButton {
  height: 42px;
  border-bottom-right-radius: 0px;
}
.buttonsContainer {
  margin-top: 5px;
  display: flex;
  gap: 5px;
}

.label {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 4px;
}
