.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.textWrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
