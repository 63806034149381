body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
}

p {
  margin-bottom: 0;
}

.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0 0;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0 0 8px 8px;
}

.ant-float-btn-icon {
  width: 24px !important;
  font-size: 24px !important;
}

.cs-message__content {
  background-color: #f6fbfc !important;
}

.ant-table {
  border-radius: 16px !important;
}

.ant-table-footer {
  background-color: #fff !important;
}
