.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: #f5f7fa;
}

.logo {
  margin-bottom: 24px;
}

.supplierInfoForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 24px;
}

.supplierInfoItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.interfacesBox {
  display: flex;
  align-items: center;
  gap: 40px;
}

.interface {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
