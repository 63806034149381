.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.logoBox {
  display: flex;
  align-items: center;
  gap: 32px;
}

.logo {
  width: 160px;
  aspect-ratio: 160/48;
}

.version {
  font-size: 9px;
  color: #00000040;
}

.addIcon {
  font-size: 18px;
  color: #1677ff;
  cursor: pointer;
}

.addBtn {
  padding: 8px;
  & button {
    width: 100%;
  }
}

.info {
  display: flex;
  align-items: center;
  gap: 64px;
}

.chatInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 24px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  font-size: 14px;
  line-height: 22px;
}

.email {
  font-size: 12px;
  line-height: 20px;
  color: #00000040;
}

.counterBadge {
  sup {
    top: 12px !important;
  }
}
